import type { ComponentType } from "react";

import { useOptionMapLoader, type OptionMapLoaderProps } from "../../hooks/useOptionMapLoader.js";
import type { BaseComboboxProps } from "./BaseCombobox.js";
import BaseCombobox from "./BaseCombobox.js";
import type TriggerProps from "./models/TriggerProps.js";
import SingleSelectTrigger from "./triggers/SingleSelectTrigger.js";

export type StringSingleSelectComboboxProps = Omit<
  BaseComboboxProps<string>,
  "trigger" | "onChange" | "selectedOptions" | "stringifyValue"
> &
  Omit<OptionMapLoaderProps, "initialOptions" | "values"> & {
    value: string | null;
    onChange: (value: string | null) => void;
    trigger?: ComponentType<TriggerProps<string>>;
  };

const StringSingleSelectCombobox = ({
  trigger = SingleSelectTrigger,
  value,
  onChange,
  initialOptionMap,
  loadOptionMap,
  options,
  ...props
}: StringSingleSelectComboboxProps) => {
  const { selectedOptions, addOptions } = useOptionMapLoader({
    values: value != null ? [value] : [],
    initialOptionMap,
    loadOptionMap,
    initialOptions: options,
  });

  return (
    <BaseCombobox
      {...props}
      options={options}
      trigger={trigger}
      onChange={(options) => {
        addOptions(options);
        onChange(options[0]?.value ?? null);
      }}
      selectedOptions={selectedOptions}
    />
  );
};

export default StringSingleSelectCombobox;
